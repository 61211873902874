.yellow {
    background-color: #FFC82F;
}

.blue {
    background-color: #2D2F8A;
}

.modal-body {
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
}

.modal-title {
    font-size: 28px;
    font-weight: 500;
    line-height: 60px;
}

.line {
    color: #A1A1A1;
    margin-top: 15px;
    margin-bottom: 10px;
    border-top-width: 2px
}

.density-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 5px;
}

.density-body {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 3px;
}

.densities-container {
    /* columns: 2 auto; */
    display: grid;
    grid-template-columns: 50% 50%;
}


@media only screen and (max-width: 767px) {
    .densities-container {
        /* columns: 2 auto; */
        display: block;
        /* grid-template-columns: 50% 50%; */
    }
}

.density-object {
    margin-top: 5px;
    margin-bottom: 5px;
}

.modal-class {
    width: 60%;
}

@media only screen and (max-width: 767px) {
    .modal-class {
        width: 80%;
    }
}