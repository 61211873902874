.right-landing {
    height: 100vh;
    position: relative;
}

.right-landing img {
    height: 100vh;
   position: absolute;
   right: 0;
}

.mobile-head {
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 1%;
  line-height: 40px;
  color: white;
  text-align: center;
}

.mobile-body {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1%;
  line-height: 26px;
  color: white;
  text-align: center;
}

.mobile-header {
  background: 
    linear-gradient(
      rgba(0, 0, 0, 0.37), 
      rgba(0, 0, 0, 0.38)
    ),
    url('../../assets/landing-photo-mobile.png');
    background-size: cover;
    height: 55vh;
    align-items: center;
}

@media only screen and (max-width: 320px) {
  .mobile-header {
    /* margin: 0 20px; */
    height: auto;
  }
}

.gray {
  background-color: #A5ABAF;
}
.body-font {
  font-size: 18px;
  line-height: 36px;
}